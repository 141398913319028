<template>
  <b-card>
    <!-- media -->
    <div class="d-flex justify-content-between">
      <b-media no-body>
        <b-media-aside>
          <b-link>
            <b-avatar rounded :src="avatar" size="90" @click="$refs.profileFile.$el.click()" />
          </b-link>
          <!--/ avatar -->
        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <!-- upload button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.profileFile.$el.click()"
          >
            Upload
          </b-button>
          <b-form-file
            ref="profileFile"
            v-model="profileFile"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @input="setPreview"
            @change="updateProfile"
          />
          <!--/ upload button -->
          <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
        </b-media-body>
      </b-media>

      <div>
        <b-button size="sm" variant="warning" v-b-modal.confirm>Set Inactive</b-button>
      </div>
    </div>
    <!--/ media -->

    <b-modal id="confirm" cancel-variant="secondary" size="xs" title="Confirm" hide-footer>
      <b-card-text>Are you sure change it?</b-card-text>

      <div class="d-flex justify-content-end">
        <b-button variant="primary mr-1" size="sm" @click="$bvModal.hide('confirm')">No</b-button>
        <b-button variant="outline-primary" size="sm" @click="updateEmployeeStatus()">Yes</b-button>
      </div>
    </b-modal>

    <!-- form -->
    <b-form class="mt-2" @submit.prevent="submitForm">
      <b-row>
        <b-col sm="6" md="4">
          <b-form-group label="* HR Code" label-for="hr-code">
            <b-form-input id="hr-code" v-model="userData.hrcode" placeholder="HR Code" name="hr_code" readonly />
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group label="* Fullname" label-for="fullname" required>
            <b-form-input id="fullname" v-model="userData.name" placeholder="Fullname" name="fullname" />
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group label="Gender" label-for="gender">
            <v-select v-model="userData.gender" :options="genders" :reduce="(g) => g.value" />
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group label="Birthday" label-for="birthday">
            <flat-pickr
              v-model="userData.birthday"
              :config="{
                dateFormat: 'Y-m-d',
                altInput: true,
                altFormat: 'F j, Y',
                allowInput: true,
              }"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group label="Birthplace" label-for="birthplace">
            <b-form-input id="birthplace" v-model="userData.birthplace" placeholder="Birthplace" name="birthplace" />
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group label="Marital Status" label-for="marital_status">
            <b-form-select id="marital_status" v-model="userData.marital_status" name="marital_status">
              <option value="single">Single</option>
              <option value="married">Married</option>
              <option value="divorced">Divorced</option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group label="Dependent" label-for="dependent">
            <b-form-select id="dependent" v-model="userData.dependent" name="dependent">
              <option value="0">Not Marry</option>
              <option value="1">Marry</option>
              <option value="2">Marry One Child</option>
              <option value="3">Marry Two Child</option>
              <option value="4">Marry Three Child</option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group label="Nationality" label-for="nationality">
            <b-form-select id="nationality" v-model="userData.nationality" name="nationality">
              <option
                v-for="nationality in nationalities"
                :value="nationality.name"
                :key="nationality.name"
                :selected="nationality.name === nationality"
              >
                {{ nationality.name }}
              </option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group label="Religion" label-for="religion">
            <b-form-select id="religion" v-model="userData.religion" name="religion">
              <option
                v-for="religion in religions"
                :value="religion.name"
                :key="religion.name"
                :selected="religion.name === religion"
              >
                {{ religion.name }}
              </option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group label="Number Identification" label-for="number_identification">
            <b-form-input
              id="number_identification"
              v-model="userData.number_of_identity"
              placeholder="Number Identification"
              name="number_identification"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group label="Place of Issue" label-for="place-of-issue">
            <b-form-input
              id="place-of-issue"
              v-model="userData.place_of_identity"
              placeholder="Place of Issue"
              name="place_of_issue"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group label="Branch" label-for="branch">
            <b-form-select id="branch" v-model="userData.branch_id" name="branch">
              <option
                v-for="branch in branches"
                :value="branch.code"
                :key="branch.id"
                :selected="branch.code === userData.branch_id"
              >
                {{ branch.name }}
              </option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <!-- <b-col sm="6" md="4">
          <b-form-group
            label="Education Background"
            label-for="education-background"
          >
            <b-form-select
              id="education-background"
              v-model="userData.education"
              name="education_background"
            >
              <option
                v-for="educationBackground in educationBackgrounds"
                :value="educationBackground.name"
                :key="educationBackground.name"
                :selected="educationBackground.name == userData.education"
              >
                {{ educationBackground.name }}
              </option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group label="Name of Institution" label-for="name-of-instute">
            <b-form-input
              id="name-of-instute"
              v-model="userData.education"
              placeholder="Name of Instute"
              name="name_of_instute"
            ></b-form-input>
          </b-form-group>
        </b-col> -->

        <b-col cols="12" class="text-right">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-3" type="submit">
            Save changes
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { BMedia, BMediaAside, BMediaBody } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ToastificationContent,
    BMedia,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileFile: null,

      id: "",
      avatar: "",
      hrcode: "",
      name: "",
      gender: "",
      birthday: "",
      birthplace: "",
      marital_status: "",
      dependent: "",
      nationality: "",
      religion: "",
      number_identification: "",
      place_of_issue: "",
      branch: "",
      education_background: "",
      name_of_institution: "",
      email: "",

      genders: [
        {
          label: "Male",
          value: "MALE",
        },
        {
          label: "Female",
          value: "FEMALE",
        },
      ],

      nationalities: [
        {
          id: 1,
          name: "Indonesia",
        },
        {
          id: 2,
          name: "Singapura",
        },
        {
          id: 3,
          name: "Malaysia",
        },
      ],

      branches: [],

      educationBackgrounds: [
        {
          id: 1,
          name: "Junior High School",
        },
        {
          id: 2,
          name: "Senior High School",
        },
        {
          id: 3,
          name: "Diploma",
        },
        {
          id: 4,
          name: "Associate Degree",
        },
        {
          id: 5,
          name: "Bachelor",
        },
        {
          id: 6,
          name: "Master",
        },
        {
          id: 7,
          name: "Doctoral",
        },
      ],

      religions: [
        {
          id: 1,
          name: "Islam",
        },
        {
          id: 2,
          name: "Kristen",
        },
        {
          id: 3,
          name: "Katolik",
        },
        {
          id: 4,
          name: "Hindu",
        },
        {
          id: 5,
          name: "Budha",
        },
        {
          id: 6,
          name: "Konghucu",
        },
      ],
    };
  },

  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },

  mounted() {
    this.get_branch();

    setTimeout(() => {
      this.avatar = this.userData.profile_photo_path;
    }, 3000);
  },

  methods: {
    get_branch() {
      this.callApi({
        method: "GET",
        url: "/branches/fetch",
        success: (res) => {
          res.result.data.data.map((item) => {
            this.branches.push({
              id: item.code,
              name: item.name,
              value: item.code,
              text: item.name,
              code: item.code,
            });
          });
        },
      });
    },

    setPreview() {
      const file = this.$refs.profileFile.$el.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.avatar = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    updateProfile() {
      const formData = new FormData();
      formData.append("profile_photo_path", this.$refs.profileFile.$el.files[0]);
      this.callApi({
        method: "POST",
        url: "/users/update/" + this.hashid(this.userData.id),
        data: formData,
        success: (res) => {
          this.$bvToast.toast("Data has been updated", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        },
      });
    },

    updateEmployeeStatus() {
      this.callApi({
        url: `/users/update/${this.hashid(this.userData.id)}`,
        method: "POST",
        data: {
          is_active: this.userData.is_active == 1 ? 0 : 1,
        },
        success: (res) => {
          this.$bvToast.toast("Data has been updated", {
            title: "Success",
            variant: "success",
            solid: true,
          });

          this.$bvModal.hide("confirm");
        },
      });
    },

    submitForm() {
      this.callApi({
        method: "POST",
        url: `/users/update/${this.hashid(this.userData.id)}`,
        data: {
          name: this.userData.name,
          gender: this.userData.gender,
          birthday: this.userData.birthday,
          birthplace: this.userData.birthplace,
          marital_status: this.userData.marital_status,
          dependent: this.userData.dependent,
          nationality: this.userData.nationality,
          religion: this.userData.religion,
          number_of_identity: this.userData.number_of_identity,
          place_of_identity: this.userData.place_of_identity,
          branch_id: this.userData.branch_id,
          phone: "0819281923",
        },
        success: (res) => {
          this.$bvToast.toast("Data has been updated", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        },
      });
    },
  },
};
</script>
