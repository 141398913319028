<template>
  <div>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">General</span>
        </template>
        <detail-general :userData="userData" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ActivityIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Job & Department</span>
        </template>
        <detail-job :userData="userData" />
      </b-tab>
      <!-- <b-tab>
        <template #title>
          <feather-icon icon="DollarSignIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Sallary</span>
        </template>
        <detail-sallary :userData="userData" />
      </b-tab> -->
      <b-tab>
        <template #title>
          <feather-icon icon="BookIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Education</span>
        </template>
        <detail-education :userData="userData" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="BriefcaseIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Career Experience</span>
        </template>
        <detail-career-experience :userData="userData" />
      </b-tab>
      <!-- <b-tab>
        <template #title>
          <feather-icon icon="FramerIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Contract</span>
        </template>
        <detail-contract :userData="userData" />
      </b-tab> -->
      <b-tab>
        <template #title>
          <feather-icon icon="ThermometerIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Insurance</span>
        </template>
        <detail-insurance :userData="userData" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="LockIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Cretidential</span>
        </template>
        <detail-cretidential :userData="userData" />
      </b-tab>
      <!-- <b-tab>
        <template #title>
          <feather-icon icon="FileIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Attachment</span>
        </template>
        <detail-attachment :userData="userData" />
      </b-tab> -->
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BCard } from "bootstrap-vue";
import DetailGeneral from "./DetailGeneral.vue";
import DetailJob from "./DetailJob.vue";
import DetailEducation from "./DetailEducation.vue";
import DetailCareerExperience from "./DetailCareerExperience.vue";
import DetailSallary from "./DetailSallary.vue";
import DetailContract from "./DetailContract.vue";
import DetailInsurance from "./DetailInsurance.vue";
import DetailCretidential from "./DetailCretidential.vue";
import DetailAttachment from "./DetailAttachment.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      userData: {},
    };
  },

  components: {
    ToastificationContent,
    BCard,
    BTabs,
    BTab,
    DetailGeneral,
    DetailJob,
    DetailCareerExperience,
    DetailEducation,
    DetailSallary,
    DetailContract,
    DetailInsurance,
    DetailCretidential,
    DetailAttachment,
  },

  mounted() {
    if (this.$route.params.id) {
      localStorage.setItem("detailEmp", this.$route.params.id);
    }
  },

  created() {
    // set delay 3 second to get data
    setTimeout(() => {
      this.callApi({
        method: "GET",
        url: "/users/getAll",
        params: {
          id: this.hashid(localStorage.getItem("detailEmp")),
        },
        success: (res) => {
          this.userData = res.result.data[0];
        },
        error: (err) => {
          console.log(err);
        },
      });
    }, 500);
  },
};
</script>
