<template>
  <div>
    <!-- <b-card> {{ jobData }} </b-card> -->
    <b-card sub-title="Job & Department">
      <form @submit.prevent="submit_form">
        <b-row>
          <b-col md="6">
            <b-form-group label="* Department" label-for="department">
              <v-select
                id="department"
                v-model="department"
                :options="departments"
                :reduce="(department) => department.id"
                label="name"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="6">
            <b-form-group label="Sub Department" label-for="sub-department">
              <v-select
                id="sub-department"
                v-model="sub_department"
                :options="sub_departments"
                :reduce="(sub_department) => sub_department.id"
                label="name"
              />
            </b-form-group>
          </b-col> -->
          <b-col md="6">
            <b-form-group label="* Job Level" label-for="job-level">
              <v-select
                id="job-level"
                v-model="job_level"
                :options="job_levels"
                :reduce="(job_level) => job_level.id"
                label="name"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="* Job Position" label-for="job-position">
              <v-select
                id="job-position"
                v-model="job_position"
                :options="job_positions"
                :reduce="(job_position) => job_position.id"
                label="name"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="* Employee Type" label-for="employee-type">
              <v-select
                id="employee-type"
                v-model="employee_type"
                :options="employee_types"
                :reduce="(employee_type) => employee_type.id"
                label="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="text-right mt-2">
          <b-button type="submit" variant="primary" class="btn-ripple">
            Save
          </b-button>
        </div>
      </form>
    </b-card>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";

export default {
  data() {
    return {
      departments: [],
      sub_departments: [],
      job_levels: [],
      job_positions: [],
      employee_types: [],

      department:
        this.userData.department != null ? this.userData.department.name : "",
      sub_department:
        this.userData.department != null ? this.userData.department.name : "",
      job_level:
        this.userData.job_level != null ? this.userData.job_level.name : "",
      job_position: this.userData.team != null ? this.userData.team.name : "",
      employee_type:
        this.userData.employee_type != null
          ? this.userData.employee_type.name
          : "",
    };
  },

  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },

  directives: {
    Ripple,
  },

  mounted() {
    this.get_department();
    this.get_job_level();
    this.get_job_position();
    this.get_employee_type();
  },

  methods: {
    get_department() {
      this.callApi({
        url: "departments/fetch",
        method: "GET",
        success: (res) => {
          res.result.map((item) => {
            this.departments.push({
              id: item.id,
              name: item.name,
              text: item.name,
            });
          });
        },
      });
    },

    // get_sub_department(id) {
    //   this.callApi({
    //     url: "departments/fetch",
    //     params: {
    //       parent_id: id,
    //     },
    //     method: "GET",
    //     success: (res) => {
    //       res.result.map((item) => {
    //         this.sub_departments.push({
    //           id: item.id,
    //           name: item.name,
    //           text: item.name,
    //         });
    //       });
    //     },
    //   });
    // },

    get_job_level() {
      this.callApi({
        url: "job_levels/fetch",
        method: "GET",
        success: (res) => {
          res.result.map((item) => {
            this.job_levels.push({
              id: item.id,
              name: item.name,
              text: item.name,
            });
          });
        },
      });
    },

    get_job_position() {
      this.callApi({
        url: "teams/fetch",
        method: "GET",
        success: (res) => {
          res.result.map((item) => {
            this.job_positions.push({
              id: item.id,
              name: item.name,
              text: item.name,
            });
          });
        },
      });
    },

    get_employee_type() {
      this.callApi({
        url: "employee_types/fetch",
        method: "GET",
        success: (res) => {
          res.result.map((item) => {
            this.employee_types.push({
              id: item.id,
              name: item.name,
              text: item.name,
            });
          });
        },
      });
    },

    submit_form() {
      this.callApi({
        url: `users/update/${this.hashid(localStorage.getItem("detailEmp"))}`,
        method: "POST",
        data: {
          department_id: this.department,
          job_level_id: this.job_level,
          team_id: this.job_position,
          employee_type_id: this.employee_type,
        },
        success: (res) => {
          // notification
          this.$bvToast.toast("Data has been updated", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        },
      });
    },
  },
};
</script>
