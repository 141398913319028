<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between align-items-center mb-1">
        <div class="card-title">
          Career Experience History <br />
          <span style="font-size: 14px; color: darkgray">
            The last historical experience will be used for further data
          </span>
        </div>
        <button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="btn btn-primary d-flex align-items-center"
          v-b-modal.add-experience
        >
          <feather-icon size="16" icon="PlusIcon" class="mr-50" /> Add
        </button>
      </div>
      <b-card-body>
        <app-timeline>
          <app-timeline-item
            v-for="(experience, index) in experiences"
            :key="index"
            :title="experience.company_name"
            :subtitle="experience.job_title"
            :from_date="experience.start_date"
            :to_date="experience.end_date"
            :target="'update_' + experience.id"
            variant="success"
          />
        </app-timeline>
      </b-card-body>
    </b-card>
    <b-modal
      id="add-experience"
      hide-footer
      no-close-on-backdrop
      centered
      size="lg"
    >
      <div class="px-3 pb-3 pt-1">
        <div class="text-center mb-3" style="line-height: 1px">
          <h1 class="mb-2">Add Career Experience</h1>
          <span>
            The last historical experience will be used for further data
          </span>
        </div>
        <form @submit.prevent="add_experience()">
          <b-row>
            <b-col md="6">
              <b-form-group label="* Company Name" label-for="company-name">
                <b-form-input id="company-name" v-model="company_name" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="* Job Title" label-for="job-title">
                <b-form-input id="job-title" v-model="job_title" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="* Employment Type"
                label-for="employmeny-type"
              >
                <b-form-select id="employmeny-type" v-model="employment_type">
                  <option value="1">Full Time</option>
                  <option value="2">Part Time</option>
                  <option value="3">Contract</option>
                  <option value="4">Internship</option>
                  <option value="5">Volunteer</option>
                  <option value="6">Other</option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="* Location" label-for="location">
                <b-form-input id="location" v-model="location" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <b-form-checkbox
                  v-model="currently_working"
                  id="currently-working"
                  v-if="!end_currently_working"
                >
                  I am currently working here
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="* Start Date" label-for="start-date">
                <flat-pickr
                  id="start-date"
                  v-model="start_date"
                  :config="{
                    dateFormat: 'Y-m-d',
                    altInput: true,
                    altFormat: 'F j, Y',
                    allowInput: true,
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="!currently_working">
              <b-form-group label="* End Date" label-for="end-date">
                <flat-pickr
                  id="end-date"
                  v-model="end_date"
                  class="form-control"
                  :config="{
                    dateFormat: 'Y-m-d',
                    altInput: true,
                    altFormat: 'F j, Y',
                    allowInput: true,
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <b-form-checkbox
                  v-model="end_currently_working"
                  @change="changeCurrentlyWorking()"
                  id="end-currently-working"
                >
                  End currently working now
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Description" label-for="description">
                <b-form-textarea id="description" v-model="description" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Optional: documents, photos, sites, videos, and presentations."
                label-for="media"
              >
                <b-form-file
                  id="media"
                  v-model="media"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept="image/*,application/pdf,.doc,.docx,.ppt,.pptx,.odt,.xls,.xlsx,.txt,.rtf,.zip,.rar,.7z,.tar,.gz,.mp4,.mp3,.wav,.avi,.mov,.wmv,.flv,.mkv,.3gp,.webm,.ogg,.ogv,.m4v,.m4a,.mpg,.mpeg,.swf,.wma,.aac,.flac,.m4b,.m4r,.m4p,.m3u,.m3u8,.pls,.cue,.aif,.aiff,.aifc,.dm"
                  multiple
                ></b-form-file>
              </b-form-group>
            </b-col>
            <b-col md="12" class="mt-2">
              <b-form-group>
                <b-button variant="primary" class="float-right" type="submit">
                  Save
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </form>
      </div>
    </b-modal>

    <b-modal
      v-for="experience in experiences"
      :key="experience.id"
      :id="'update_' + experience.id"
      hide-footer
      no-close-on-backdrop
      centered
      size="lg"
      data-bs-focus="false"
    >
      <div class="px-3 pb-3 pt-1">
        <div class="text-center mb-3" style="line-height: 1px">
          <h1 class="mb-2">Detail Experience History</h1>
          <span>
            The last historical experience will be used for further data
          </span>
        </div>
        <form @submit.prevent="edit_experience(experience)">
          <b-row>
            <b-col md="6">
              <b-form-group label="* Company Name" label-for="company-name">
                <b-form-input
                  id="company-name"
                  v-model="experience.company_name"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="* Job Title" label-for="job-title">
                <b-form-input id="job-title" v-model="experience.job_title" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="* Employment Type"
                label-for="employmeny-type"
              >
                <b-form-select
                  id="employmeny-type"
                  v-model="experience.employee_type_id"
                >
                  <option value="1">Full Time</option>
                  <option value="2">Part Time</option>
                  <option value="3">Contract</option>
                  <option value="4">Internship</option>
                  <option value="5">Volunteer</option>
                  <option value="6">Other</option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="* Location" label-for="location">
                <b-form-input id="location" v-model="experience.location" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <b-form-checkbox
                  v-model="experience.currently_working"
                  id="currently-working"
                  v-if="!end_currently_working"
                >
                  I am currently working here
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="* Start Date" label-for="start-date">
                <flat-pickr
                  id="start-date"
                  v-model="experience.start_date"
                  :config="{
                    dateFormat: 'Y-m-d',
                    altInput: true,
                    altFormat: 'F j, Y',
                    allowInput: true,
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="!currently_working">
              <b-form-group label="* End Date" label-for="end-date">
                <flat-pickr
                  id="end-date"
                  v-model="experience.end_date"
                  class="form-control"
                  :config="{
                    dateFormat: 'Y-m-d',
                    altInput: true,
                    altFormat: 'F j, Y',
                    allowInput: true,
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <b-form-checkbox
                  v-model="experience.end_currently_working"
                  @change="changeCurrentlyWorking()"
                  id="end-currently-working"
                >
                  End currently working now
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Description" label-for="description">
                <b-form-textarea
                  id="description"
                  v-model="experience.description"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Optional: documents, photos, sites, videos, and presentations."
                label-for="media"
              >
                <b-form-file
                  id="media"
                  v-model="experience.media"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept="image/*,application/pdf,.doc,.docx,.ppt,.pptx,.odt,.xls,.xlsx,.txt,.rtf,.zip,.rar,.7z,.tar,.gz,.mp4,.mp3,.wav,.avi,.mov,.wmv,.flv,.mkv,.3gp,.webm,.ogg,.ogv,.m4v,.m4a,.mpg,.mpeg,.swf,.wma,.aac,.flac,.m4b,.m4r,.m4p,.m3u,.m3u8,.pls,.cue,.aif,.aiff,.aifc,.dm"
                  multiple
                ></b-form-file>
              </b-form-group>
            </b-col>
            <b-col md="12" class="mt-2">
              <b-form-group>
                <b-button variant="primary" class="float-right" type="submit">
                  Update Career Experience
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<style>
.modal .modal-header {
  background: none !important;
}

.dropzone {
  border: 2px dashed #0561bd;
  border-radius: 0.25rem;
  background: #fff;
  min-height: 50px;
  padding: 0rem !important;
  text-align: center;
  color: #adb5bd;
  font-size: 1.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dropzone .dz-message {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0px;
  color: #adb5bd;
}
</style>

<script>
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import axios from "axios";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    vueDropzone: vue2Dropzone,
    flatPickr,
    AppTimeline,
    AppTimelineItem,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      experiences: [],
      company_name: "",
      position: "",
      currently_working: false,
      start_date: "",
      end_date: "",
      end_currently_working: false,
      description: "",
      employment_type: "",
      location: "",
      job_title: "",
      media: [],

      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 6,
        headers: { "My-Awesome-Header": "header value" },
      },
    };
  },
  mounted() {
    this.callApi({
      url: "/career_experiences/fetch",
      method: "GET",
      params: {
        user_id: this.hashid(localStorage.getItem("detailEmp")),
      },
      success: (response) => {
        response.result.forEach((element) => {
          this.experiences.push(element);
        });
      },
    });
  },
  computed: {},
  methods: {
    add_experience() {
      this.callApi({
        method: "POST",
        url: "/career_experiences/create",
        data: {
          user_id: JSON.parse(localStorage.getItem("sw_auth_data")).id,
          company_name: this.company_name,
          position: this.position,
          currently_working: this.currently_working,
          start_date: this.start_date,
          end_date: this.end_date,
          end_currently_working: this.end_currently_working,
          description: this.description,
          employee_type_id: this.employment_type,
          location: this.location,
          job_title: this.job_title,
          media: this.media,
        },
        success: (response) => {
          this.experiences.push(response.result);
          this.$bvModal.hide("add-education");
        },
      });
    },

    edit_experience(experience) {
      this.callApi({
        method: "POST",
        url: `/career_experiences/update/${this.hashid(experience.id)}`,
        data: {
          user_id: JSON.parse(localStorage.getItem("sw_auth_data")).id,
          company_name: experience.company_name,
          position: experience.position,
          currently_working: experience.currently_working,
          start_date: experience.start_date,
          end_date: experience.end_date,
          end_currently_working: experience.end_currently_working,
          description: experience.description,
          employee_type_id: experience.employee_type_id,
          location: experience.location,
          job_title: experience.job_title,
          media: experience.media,
        },
        success: (response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: "Education has been updated",
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.$bvModal.hide("update_" + experience.id);
        },
        error: (error) => {
          console.log(error);
        },
      });
    },

    delete_education(id) {
      this.callApi({
        method: "DELETE",
        url: `/career_experiences/delete/${this.hashid(id)}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sw_auth_token")}`,
        },
      })
        .then((response) => {
          this.experiences = this.experiences.filter((education) => {
            return education.id != id;
          });
          this.$bvModal.hide("update_" + id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
</style>